<template>
  <div class="bg">
    <div class="wrapper" id="main">
      <div class="wellbeing card lowMax">
        {{ getContent(1343) }}
      </div>
      <div class="knowledgeOptions flexRow">
        <a href="../support/self/knowledge">
          <div class="knowledge card click highMax">
            <div class="flexColumn">
              {{ getContent(1345) }}
              <p class="subheader">
                {{ getContent(1365) }}
              </p>
            </div>
          </div>
        </a>
        <a href="../support/self/practice">
          <div class="practise card click highMax">
            <div class="flexColumn">
              {{ getContent(1344) }}
              <p class="subheader">{{ getContent(1366) }}</p>
            </div>
          </div>
        </a>
        <a href="../support/self/viewAll">
          <div class="viewAll card click highMax">
            <div class="flexColumn">
              {{ getContent(1346) }}
              <p class="subheader">
                <!-- EMPTY?! // Add to DB: -->A combined view of Knowledge and
                Practice
              </p>
            </div>
          </div>
        </a>
      </div>
      <div class="wrapper">
        <router-link :to="{ name: 'Recommendation - Self Support' }">
          <div class="recommendation card click lowMax">
            <div class="flexColumn">
              User Recommendations<!--PUT INTO DB!-->
              <p class="subheaderHorizontal">
                {{ getContent(1367) }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div
      class="flexColumn"
      style="width: 100%; justify-content: center; align-items: center"
    >
      <div class="behindPS">
        <div class="image">
          <img
            src="https://media.kaiser.fyi/mindsupport/500/ScienceMSabstractdesign.png"
          />
        </div>
        <div class="description">
          <div class="header">
            <div class="top">{{ getContent(1353) }}</div>
            <div class="bottom">{{ getContent(1354) }}</div>
          </div>
          <ul v-if="!myWidth">
            <!-- Desktop Liste -->
            <li>
              {{ getContent(1347) }}
            </li>
            <li>
              {{ getContent(1348) }}
            </li>
            <li>
              {{ getContent(1349) }}
            </li>
            <li>
              {{ getContent(1350) }}
            </li>
            <li>
              {{ getContent(1351) }}
            </li>
            <li>
              {{ getContent(1352) }}
            </li>
          </ul>
        </div>
      </div>
      <ul v-if="myWidth" id="points">
        <!-- Mobile Liste außerhalb -->
        <li>
          {{ getContent(1347) }}
        </li>
        <li>
          {{ getContent(1348) }}
        </li>
        <li>
          {{ getContent(1349) }}
        </li>
        <li>
          {{ getContent(1350) }}
        </li>
        <li>
          {{ getContent(1351) }}
        </li>
        <li>
          {{ getContent(1352) }}
        </li>
      </ul>
      <a
        href="https://www.mja.com.au/system/files/issues/187_07_011007/kel10278_fm.pdf"
        target="_blank"
      >
        <div class="readMore">
          <img
            src="https://media.kaiser.fyi/mindsupport/250/Read_more_2.png"
          /></div
      ></a>
    </div>
  </div>
</template>

<script>
import { loadData } from "@/utils";
export default {
  data() {
    return {
      /* Important for Mobile Layouts in HTML */
      myWidth: screen.height / screen.width > 1,
      myLanguage: this.$router.currentRoute.params.language.toUpperCase(),
      text: {},
    };
  },
  methods: {
    calcMyWidth() {
      this.myWidth = screen.height / screen.width > 1;
    },
    getContent: function (key) {
      return this.text[key];
    },
  },
  async created() {
    this.text = await loadData("selfSupport", this.myLanguage);
    window.addEventListener("resize", this.calcMyWidth);
    screen.orientation.addEventListener("change", this.calcMyWidth);
    screen.orientation.onchange = this.calcMyWidth;
  },
  destroyed() {
    window.removeEventListener("resize", this.calcMyWidth);
    screen.removeEventListener("change", this.calcMyWidth);
  },
};
</script>

<style scoped>
.bg {
  background: linear-gradient(0deg, #dfab79, #ffffff);
}
.wrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.wrapper#main {
  /* To prevent gray background from top margin */
  margin-top: 0;
  border-top: 1vh solid white;
}
.knowledgeOptions {
  justify-content: space-between;
  cursor: pointer;
  text-transform: uppercase;
  max-width: 1280px;
  width: 100%;
}
.card {
  /*font-size: clamp(1rem, -0.875rem + 8.333vw, 3.5rem);*/
  text-shadow: 0px 2px 4px #666666;
  font-weight: bold;
  text-transform: uppercase;
  box-sizing: border-box;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  padding-top: 10%;
  background-size: 100% 100%;
  color: white;
  transition: all 0.2s ease-in-out;
}
.click {
  font-family: var(--h2);
}
.click:hover {
  opacity: 0.8;
  transform: scale(0.98);
  /*border: 2px solid rgb(29, 104, 148);*/
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}
.highMax {
  margin: 1vh 0vw;
  --width: calc((1279px - 4vw) / 3);
  width: var(--width);
  height: calc(var(--width) * (1125 / 600));
  font-size: 74px;
}
.lowMax {
  margin: 1vh 0vw;
  --width: 1280px;
  width: var(--width);
  height: calc(var(--width) * (242 / 1280));
  font-size: 84px;
}
.wellbeing {
  background-image: url(https://media.kaiser.fyi/mindsupport/1280/top.png);
  font-family: var(--h1);
  font-size: 127.8px;
  font-weight: bold;
  padding-top: 9%;
}
.knowledge {
  margin-right: 1vw;
  background-image: url(https://media.kaiser.fyi/mindsupport/600/knowledge.png);
  /* padding-bottom: 75%; */
}
.practise {
  margin-left: 1vw;
  margin-right: 1vw;
  background-image: url(https://media.kaiser.fyi/mindsupport/600/practice.png);
  /* padding-top: 5%;
  padding-bottom: 85%; */
}
.viewAll {
  margin-left: 1vw;
  background-image: url(https://media.kaiser.fyi/mindsupport/600/viewAll.png);
}
.recommendation {
  background-image: url(https://media.kaiser.fyi/mindsupport/1280/recommendation.png);
  padding-top: 4%;
}
.knowledge,
.practise,
.viewAll {
  padding-bottom: 100%;
}
.knowledgeOptions .flexColumn {
  justify-content: space-between;
  padding-top: 10%;
}
.knowledgeOptions .subheader {
  font-size: 20px;
  font-family: var(--text);
  text-transform: none;
  line-height: 1.7rem;
  /* text-shadow: 1px 1px rgba(0, 0, 0, 0.2); */
  padding-right: 5%;
  padding-left: 5%;
  padding-top: 5%;
  padding-bottom: 0;
}
.recommendation .subheaderHorizontal {
  font-size: 20px;
  font-family: var(--text);
  text-transform: none;
  line-height: 1.5rem;
  padding-top: 3%;
}
.behindPS {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1280px;
  justify-content: center;
  margin-top: 7em;
  margin-bottom: 7em;
}
.behindPS .image {
  display: flex;
  justify-content: center;
  max-width: 30%;
}
.behindPS .image img {
  /* What is up with the width here? Gets squished!
   * This doesn't happen in PeerSupport!
   * Workaround: width * 2 */
  width: calc(384px * 2);
  height: 384px;
  margin: 50px 50px 0 50px;
}
.behindPS .description {
  font-size: 1em;
  margin: 50px 50px 0 50px;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.behindPS .description li,
.flexColumn li {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-left: -20px;
}
.behindPS .description .header {
  font-size: 7em;
  line-height: 1em;
}
.behindPS .description .header .top {
  color: var(--c3); /* darker: color: #000761;*/
  font-family: var(--h1);
  font-weight: bold;
  text-transform: uppercase;
}
.behindPS .description .header .bottom {
  font-size: 0.75em;
  color: var(--c4);
  font-family: var(--h2);
}
.readMore {
  width: 10vw;
  margin: 1em auto;
  padding: 15px;
  transition: all 0.4s ease 0s;
}
.readMore:hover {
  opacity: 0.7;
  transform: scale(1.1);
}

@media only screen and (max-width: 1280px) {
  .wrapper#main {
    width: 95%;
  }
  .lowMax {
    margin: 1vh 0vw;
    --width: 100vw;
    /* following width calc subtracts scrollbar, important! */
    width: calc(100vw - (100vw - 100%));
    height: calc(var(--width) * (333 / 2109));
    font-size: 2em;
  }
  .highMax {
    margin: 1vh 0vw;
    --width: 32vw;
    width: calc(var(--width) - 2.1vw);
    height: calc(var(--width) * (1279 / 682));
    font-size: 2em;
  }
  .card {
    font-size: 60px;
    padding-top: 5%;
  }
  .recommendation {
    padding-top: 3%;
  }
  .recommendation .subheaderHorizontal {
    padding-top: 0;
    line-height: 0.9;
  }
  .behindPS {
    flex-direction: column;
    justify-content: center;
    flex-direction: row;
    width: 95%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .behindPS .image {
    width: 100%;
    max-width: 33%;
    height: 100%;
    margin: 0 auto;
    margin-top: 1vh;
    margin-right: 1vw;
  }
  .behindPS .image img {
    width: 100%;
    height: auto;
  }
  .behindPS .description {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  .behindPS .description .header {
    font-size: 3em;
    line-height: 1em;
    text-align: center;
    margin-top: 2em;
    overflow-x: hidden;
  }
  #points {
    width: 90%;
  }
  li {
    text-align: left;
  }
  .readMore {
    width: 20vw;
  }
}

@media only screen and (max-width: 640px) {
  /* For mobile phones: */
  .card {
    /*font-size: 3.2vh;*/
    font-size: clamp(1.2rem, -0.875rem + 8.333vw, 2rem);
    padding-top: 5%;
  }
  .card .flexColumn {
    padding-top: 0;
  }
  .wellbeing {
    font-size: 6vh;
    font-size: clamp(2rem, -0.875rem + 8.333vw, 4rem);
  }
  .knowledgeOptions .card {
    line-height: 1;
    padding-top: 5%;
  }
  .knowledgeOptions .subheader {
    font-size: 0.4em;
    line-height: 1.5em;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }
  .recommendation {
    padding-top: 5px;
    line-height: 0.9;
  }
  .recommendation .subheaderHorizontal {
    font-size: 0.4em;
    line-height: 1;
    padding-top: 0;
  }
  .behindPS .image {
    max-width: 33%;
    min-width: 150px;
  }
  .readMore {
    width: 50vw;
  }
}
</style>
